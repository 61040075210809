import React, { memo, useContext } from 'react';
import Markdown from '../../../components/shared/Markdown';
import { safetyCheck } from '../../../utils';
import PageContext from '../../../contexts/PageContext';

const KnowledgeA = () => {
    const { data, heading: Heading } = useContext( PageContext );

    return (
        safetyCheck( data.knowledge, 'body' ) && (
            <div>
                <Heading>{data.knowledge.heading}</Heading>
                <Markdown className="markdown text-sm">{data.knowledge.body}</Markdown>
            </div>
        )
    );
};

export default memo( KnowledgeA );
